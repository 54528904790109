<template>
  <div class="checkin">
    <h1>{{ $t('checkin.main_title') }}</h1>

    <LanguageSelect absolutePosition />

    <FloatingButton position="top" @click="$router.push('/home')">
      <UndoSvg />
      <span>{{ $t('floating_button.action.back') }}</span>
    </FloatingButton>

    <p>{{ $t('checkin.content.scan_qr_code') }}</p>

    <GuestWebQRCode :text="checkInLink(property.pin, reservation.pin, true)"
      :logoSrc="mystayLogo"
    />

    <div class="share-or-separator">
      <span><span>{{ $t('room_directions.content.or_separator') }}</span></span>
    </div>

    <p>{{ $t('checkin.content.enter_address') }}</p>

    <p>
      <strong class="link-view">{{ guestWebLink(property.pin, reservation.pin, false) }}</strong>
    </p>

    <p>{{ $t('checkin.content.after_checkin') }}</p>

    <ButtonInput type="button" @click="continueToHome">
      {{ $t('checkin.action.continue') }}
    </ButtonInput>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mystayLogoBase64 } from '@/constants/image.base64';
import ButtonInput from '@/components/Form/Input/ButtonInput';
import LanguageSelect from '@/components/LanguageSelect/index';
import FloatingButton from '@/components/FloatingButton';
import GuestWebQRCode from '@/components/GuestWebQRCode';
import UndoSvg from '@/assets/images/icons/undo.svg';
import { GET_PROPERTY_DETAIL } from '@/store/property';
import { GET_RESERVATION_DETAIL } from '@/store/reservation';
import { checkInLink, guestWebLink } from '@/helpers/linkHelper';

export default {
  name: 'CheckInMissing',
  async mounted() {
    if (!this.reservation) {
      await this.$router.push('/home');
    }
  },
  components: {
    ButtonInput,
    LanguageSelect,
    FloatingButton,
    UndoSvg,
    GuestWebQRCode,
  },
  computed: {
    ...mapGetters({
      property: GET_PROPERTY_DETAIL,
      reservation: GET_RESERVATION_DETAIL,
    }),
    mystayLogo() {
      return mystayLogoBase64;
    },
  },
  methods: {
    continueToHome() {
      this.$router.push('/home');
    },
    checkInLink: checkInLink,
    guestWebLink: guestWebLink,
  },
};
</script>

<style lang="scss">
  .checkin {
    text-align: center;
    width: calc(100% - 4rem);
    max-width: 50rem;
  }

  .link-view {
    font-size: 1.25rem;
  }

  .share-or-separator {
    margin-bottom: 2rem;
  }
</style>
