<template>
  <a href=""
    @click.prevent="$emit('click')"
    :style="positionStyle"
    class="floating-button"
  >
    <slot />
  </a>
</template>

<script>
export default {
  name: 'FloatingButton',
  props: {
    position: {
      type: String,
      default: 'bottom',
      required: false,
    },
  },
  computed: {
    positionStyle() {
      let style = {
        top: 'auto',
        bottom: '1rem',
      };
      if (this.position === 'top') {
        style = {
          top: '1rem',
          bottom: 'auto',
        };
      }
      return {
        left: '1rem',
        ...style,
      };
    },
  },
};
</script>

<style lang="scss">
  .floating-button {
    position: fixed;
    background: transparent;
    box-shadow: none;
    color: #000;
    padding: 1rem 2rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    z-index: 2;

    svg {
      width: 1rem;
      height: 1rem;
      margin-right: .5rem;
    }
  }
</style>